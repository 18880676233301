<!--
 * @Descripttion: 帐号信息
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-25 23:02:57
 * @LastEditTime: 2021-11-19 02:13:39
-->
<template>
  <div v-wechat-title="'【'+data.zhongzu+'-'+data.zhiye+'】'+data.title+'-犀利装备交易平台-犀利担保-犀利交易-犀利龙之谷-QQ龙交易平台'">

    <div class="breadcrumb">

      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>区服</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/GameRoleList/'+pid }">{{titleName}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{'【'+data.zhongzu+'-'+data.zhiye+'】'+data.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content3">
      <div class="c3_div"
           style="padding:0.5rem">
        <el-button @click="returnPage"
                   size="small"
                   icon="el-icon-arrow-left">上一页</el-button>
        <el-button v-if="data.favorite==0"
                   @click="favorite()"
                   size="small"
                   style="float:right;margin-right:5%"
                   class="el-icon-star-off">添加收藏</el-button>
        <el-button v-if="data.favorite==1"
                   @click="favorite()"
                   size="small"
                   style="float:right;margin-right:5%"
                   class="el-icon-star-on  el-button--warning">已收藏</el-button>

      </div>
      <br class="mBr" />
      <div class="c3_div">
        <el-descriptions border
                         :column="2"
                         :title="'【'+data.zhongzu+'-'+data.zhiye+'】'+data.title">
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="编号">{{data.game_role_id}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="访问次数">{{data.hit}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="大区">{{data.qufu}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="发布时间">{{data.create_time}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="种族">{{data.zhongzu}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="￥">{{data.price|money}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="职业">{{data.zhiye}}</el-descriptions-item>
          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="几变幻">{{data.title}}</el-descriptions-item>
          <el-descriptions-item span="2"
                                labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="简述">{{data.description}}</el-descriptions-item>

          <el-descriptions-item labelClassName="infoLable"
                                contentClassName="infoContent"
                                label="便签概述">
            <el-tag size="middle">{{data.qufu}}</el-tag>
            <el-tag size="middle">{{data.zhongzu}}</el-tag>
            <el-tag size="middle">{{data.zhiye}}</el-tag>
            <el-tag type="success">找回包赔</el-tag>
            <el-tag type="success">合同保障</el-tag>
            <el-tag type="success">免费估价</el-tag>
            <el-tag type="success">建议投诉</el-tag>
            <el-tag type="danger">谨防骗子</el-tag>
          </el-descriptions-item>

        </el-descriptions>
      </div>
      <div class="c3_div"
           style="padding-bottom:0">
        <el-divider><i class="el-icon-document">详细描述</i> </el-divider>
      </div>
      <div class="c3_div_content"
           style="word-break: break-word;padding-top:0"
           v-html="data.content">

      </div>
    </div>

  </div>
</template>

<script>
import { getCateGoryInfo, getGameRoleInfo } from "@/api";
import { addFavorite, deleFavorite } from "@/api/game_role";
import "@/styles/GameRole.css";
export default {
  components: {},

  directives: {},

  data() {
    return {
      titleName: "",
      pid: "",
      data: {
        game_role_id: "",
        contact: "",
        contacts: "",
        create_time: "",
        price: "",
        title: "",
        ZH_Type: "",
        zhiye: "",
        qufu: "",
        zhongzu: "",
        description: "",
        favorite: 0,
      },
    };
  },

  mounted() {
    this.pid = this.$route.params.pid;
    this.getCateGoryInfo();
    this.getGameRoleInfo();
  },

  methods: {
    returnPage() {
      this.$router.go(-1);
    },
    //获取分类信息
    getCateGoryInfo() {
      let params = {
        pid: this.$route.params.pid,
      };
      getCateGoryInfo(params).then((res) => {
        this.titleName = res.data.category_name;
      });
    },
    favorite() {
      if (!this.$store.state.user.memberInfo) {
        this.$message({
          message: "您还未登录请先登录！",
          type: "warning",
        });
        return;
      }

      let favorite = this.$data.data.favorite;
      //console.log(this.$data);
      if (favorite == 1) {
        deleFavorite({ game_role_id: this.$data.data.game_role_id })
          .then((res) => {
            this.$message.success(res.msg);

            this.getGameRoleInfo();
          })
          .catch(() => {});
      } else {
        addFavorite({ game_role_id: this.$data.data.game_role_id })
          .then((res) => {
            this.$message.success(res.msg);

            this.getGameRoleInfo();
          })
          .catch(() => {});
      }
    },

    //获取账号信息
    getGameRoleInfo() {
      let params = {
        game_role_id: this.$route.params.game_role_id,
      };

      getGameRoleInfo(params).then((res) => {
        this.data = res.data;

        //(res.data);
        // console.log(this.data);
      });
    },
  },
  filters: {
    //钩子，过滤器
    money(value) {
      if (!value) return "0.00";
      var intPart = Number(value).toFixed(0); //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断
      var floatPart = ".00"; //预定义小数部分
      var value2Array = value.split(".");
      //=2表示数据有小数位
      if (value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if (floatPart.length == 1) {
          //补0,
          return intPartFormat + "." + floatPart + "0";
        } else {
          return intPartFormat + "." + floatPart;
        }
      } else {
        return intPartFormat + floatPart;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>