/*
 * @Descripttion: 游戏账户操作
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-05 00:05:11
 * @LastEditTime: 2021-09-09 21:13:48
 */
const url = 'index/GameRole/'
import {
    $post
} from "@/utils"


/**
 * 游戏账号添加
 * @param {array} data 请求数据
 */
export function addGameRole(params) {
    return $post(url + 'add_game_role', params)
}
/**
 * 游戏账号修改
 * @param {array} data 请求数据
 */
export function editGameRole(params) {
    return $post(url + 'edit_game_role', params)
}

/**
 * 游戏角色删除
 * @param {array} data 请求数据
 */
export function deleGameRole(params) {
    return $post(url + 'dele_game_role', params)
}
/**
 * 添加收藏夹
 * @param {array} data 请求数据
 */
export function addFavorite(params) {
    return $post(url + 'add_favorite', params)
}

/**
 * 移除收藏夹
 * @param {array} data 请求数据
 */
export function deleFavorite(params) {
    return $post(url + 'dele_favorite', params)
}

/**
 * 已出售列表
 * @param {array} data  请求数据
 */
export function sold_out_list(params){
     return $post(url + 'sold_out_list',params)
}